<template>
  <div class="auth">
    <user-details v-if="$store.getters.isLoggedIn && canEditUserDetails() && $store.getters.user" />
    <div v-else class="row">
      <div class="col-12 col-md-3"></div>
      <div class="cache-clear col-12 col-md-6">
        <card type="primary" class="text-center">
          <scaled-image :src="clientImgSrc('landing_logo.png')" />
          <div v-if="usingComplexKeyAuth() && !$store.getters.verifying && !$store.getters.isLoggedIn">
            <div class="well">
              <p>
                The inquiry tool is only available for authorised users, please visit the Intranet page about this tool to access this service.
              </p>
            </div>
          </div>
          <div v-else-if="$store.getters.verifying || (usingComplexKeyAuth() && $store.getters.isLoggedIn)">
            <h1 class="text-success">Logging in</h1>
            <span>
              <i class="fa fa-sync fa-spin"></i>&nbsp;Verifying Login Link
            </span>
          </div>
          <div v-else-if="usingEmailLogin">
            <div v-if="$store.getters.isLoggedIn && !$store.getters.loggingOut">
              <h3 class="text-success">Successfully logged in</h3>
              <button
                class="btn btn-primary mt-4"
                @click="$router.push({ name: 'Country Select' })"
              >
                <i class="fa fa-globe"></i>&nbsp; Start Programme Inquiry
              </button>
              <br />
              <button
                class="btn btn-warning btn-sm mt-4"
                @click="$store.dispatch('logout')"
              >
                <i class="fa fa-door-open"></i>&nbsp; Logout
              </button>
            </div>
            <div v-else-if="showEmailSentMsg">
              <h1 class="text-success"><i class="fa fa-paper-plane"></i>&nbsp;Login Email Sent</h1>
              <br />
              <button class="btn btn-secondary btn-sm" @click="returnToLogin"><i class="fa fa-door-open"></i>&nbsp;Return to Login</button>
            </div>
            <div v-else-if="!$store.getters.emailSent && $store.getters.tokenExpired">
              <h1 class="text-warning"><i class="fa fa-clock"></i>&nbsp;Login Email Expired</h1>
              <p>The magic link you used has expired for security reasons, <strong>a new one has been sent</strong> to the same email address for you and should arrive shortly.</p>
              <br />
              <button class="btn btn-secondary btn-sm" @click="returnToLogin"><i class="fa fa-door-open"></i>&nbsp;Return to login</button>
            </div>
            <div v-else-if="!$store.getters.isLoggedIn && !$store.getters.emailSent">
              <h1>Login</h1>
              <div class="form-group">
                <label for="inpEmail">Email Address</label>
                <input
                  id="inpEmail"
                  autocomplete="off"
                  type="email"
                  v-model="email"
                  placeholder="Email Address"
                  class="form-control"
                  @keyup="checkIfEnter"
                />
                <span v-if="$store.getters.tokenExpired" class="text-warning"
                  >Login link has expired, Please login again</span
                >
                <span class="text-danger">{{ error }}</span>
              </div>
              <button
                class="btn btn-success"
                id="btnLogin"
                @click="getCsrfAndLogin"
              >
                <i class="fa fa-paper-plane"></i>&nbsp;Send Login Email</button
              ><br />
            </div>
          </div>
          <div v-else class="text-center">
            <h1>Login</h1>
            <p>
              Login using the button below - just enter your email address and
              some optional details
            </p>
            <button v-b-modal:modal-login class="btn btn-primary">
              <i class="fa fa-unlock"></i>&nbsp;Login
            </button>
            <login-modal
              :email="$store.getters.emailAddress"
              @logged-in="$router.push({ name: 'Country Select' })"
            >
            </login-modal>
          </div>
        </card>
      </div>
      <div class="col-12 col-md-3"></div>
    </div>
  </div>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
import axios from "axios";
import UserDetails from "../components/UserDetails";
import Card from "../components/Cards/Card";
import LoginModal from "../components/LoginModal";
import ScaledImage from "@/components/ScaledImage";
import FeaturesMixin from "@/mixins/features.mixin";
import DefaultsService from "@/services/defaults.service";

export default {
  name: "Login",
  components: {
    LoginModal,
    UserDetails,
    Card,
    ScaledImage,
  },
  props: {},
  data: () => {
    return {
      error: "",
      email: "",
      sendingEmail: false,
    };
  },
  mixins: [FeaturesMixin],
  computed: {
    usingEmailLogin() {
      return process.env.VUE_APP_USING_MAGIC_LINK_AUTH === "1";
    },
    showEmailSentMsg() {
      return !this.$store.getters.isLoggedIn &&
        this.$store.getters.emailSent &&
        !this.$store.getters.tokenExpired;
    }
  },
  methods: {
    async checkIfEnter(e) {
      if (e.keyCode === 13) {
        document.getElementById("btnLogin").focus();
        await this.getCsrfAndLogin();
      }
    },
    async getCsrfAndLogin() {
      const url = process.env.VUE_APP_API_ENDPOINT + "/csrf-cookie";

      let d = this;
      axios
        .get(url, {
          withCredentials: true
        })
        .then(function() {
          d.login();
        })
        .catch(function(error) {
          if (error.response) {
            const data = error.response.data.errors;
            console.log(data);
          }
        });
    },
    async login() {
      if (this.email === "") {
        this.error = "Please enter an email address";
        return;
      }
      this.error = "";
      this.sendingEmail = true;
      const url = process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/login";

      this.$store.commit("setEmailSent", { sent: true });
      this.$store.commit("setTokenExpired", { expired: false });
      let d = this;
      axios
        .post(url, {
          email: this.email
        })
        .then(function() {
          console.log("email sent to user");
        })
        .catch(function(error) {
          if (error.response) {
            const data = error.response.data.errors;
            console.log(data);
            if (Object.prototype.hasOwnProperty.call(data, "email")) {
              d.error = data.email[0];
            }
          }
        })
        .then(function() {
          d.sendingEmail = false;
        });
    },
    returnToLogin() {
      this.$store.commit("setEmailSent", { sent: false });
      this.$store.commit("setTokenExpired", { expired: false });
    }
  },
  async mounted() {
    if (process.env.VUE_APP_USING_MAGIC_LINK_AUTH === "1") {
      const unwatch = this.$store.watch(
        state => state.user.loggedIn,
        (newValue, oldValue) => {
          if (newValue && !oldValue) {
            this.$toasted.success("Successfully logged in!");

            // TODO: Prevent redirection failure in an config edge case where we don't redirect properly.
            // this.$router.push({ name: "Login" });
            unwatch();
          }
        }
      );
    }

    // Login link contains ?token=XYZ... fire login process if present
    if (!this.$store.getters.isLoggedIn) {
      const urlHasEmail = typeof this.$route.query.email !== "undefined";
      const urlHasPO = typeof this.$route.query.po !== "undefined";
      if (Object.prototype.hasOwnProperty.call(this.$route.query, "token")) {
        if (this.usingLocalTokenAuth() && this.$route.query.token === process.env.VUE_APP_AUTH_COMPLEX_KEY){
          this.$store.commit('setLoggedIn', { loggedIn: true });
        } else if (!this.usingLocalTokenAuth()) {
          const loginData = { token: this.$route.query.token };
          if (urlHasEmail) {
            loginData["email"] = this.$route.query.email;
          }
          if (urlHasPO) {
            loginData["po"] = this.$route.query.po;
          }
          const success = await this.$store.dispatch("loginWithToken", loginData);

          if (!success) {
            return;
          }

          DefaultsService.loadDefaults();
        }

        if (process.env.VUE_APP_USING_COMPLEX_KEY_AUTH === "1" && this.$store.getters.isLoggedIn) {
          this.$router.push({ name: "Country Select" });
        } else {
          this.$router.push({ name: "Login" });
        }
      }
    } else if (process.env.VUE_APP_USING_COMPLEX_KEY_AUTH === "1") {
      // Don't ever show the user detail page in this mode - so redirect if they try to re-auth.
      this.$router.push({ name: "Country Select" });
    }
  }
};
</script>
