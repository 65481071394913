<template>
  <div class="dashboard">
    <h1 class="text-center">Dashboard</h1>
    <br />
    <div class="row justify-content-around">
      <div class="col col-lg-5">
        <card
          class="text-center pt-2 pb-5"
          @click.native="$router.push({ name: 'Country Select' })"
        >
          <h2>Start Inquiry</h2>
          <i class="fa fa-pen fa-3x"></i>
        </card>
      </div>
      <div class="col col-lg-5">
        <card
          class="text-center pt-2 pb-5"
          @click.native="$router.push({ name: 'Programmes' })"
        >
          <h2>Programmes</h2>
          <i class="fa fa-list fa-3x"></i>
        </card>
      </div>
    </div>
    <div class="row justify-content-around">
      <div class="col col-lg-5">
        <card
          class="text-center pt-2 pb-5"
          @click.native="$router.push({ name: 'Login' })"
        >
          <h2>Login</h2>
          <i class="fa fa-user fa-3x"></i>
        </card>
      </div>
      <div class="col col-lg-5">
        <card
          class="text-center pt-2 pb-5"
          @click.native="$router.push({ name: 'About' })"
        >
          <h2>About</h2>
          <i class="fa fa-info fa-3x"></i>
        </card>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  border-width: 2px;
  cursor: pointer;
  background-color: #3472f7;
  color: #fafafa;
}
.card:hover {
  background-color: #78f5b4;
}
.card:active {
  background-color: #64e29d;
}
/* Prevent text from highlighting when clicking dash cards */
.card h2::selection {
  color: none;
  background: none;
}
.card h2::-moz-selection {
  color: none;
  background: none;
}
</style>

<script>
import Card from "../components/Cards/Card";

export default {
  name: "Home",
  components: {
    Card
  }
};
</script>
