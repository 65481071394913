<template>
  <div class="userDetails">
    <div class="row">
      <div class="col-12 col-xl-3"></div>
      <div class="col-12 col-xl-6">
        <card title="User" sub-title="User Details" type="primary">
          <slot>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="inpForename">Forename</label>
                  <input
                    id="inpForename"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Forename"
                    :value="forename"
                    @input="
                      $store.commit('updateDetails', {
                        forename: $event.target.value
                      })
                    "
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="inpSurname">Surname</label>
                  <input
                    id="inpSurname"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Surname"
                    :value="surname"
                    @input="
                      $store.commit('updateDetails', {
                        surname: $event.target.value
                      })
                    "
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="inpEmail">Email</label>
                  <input
                    id="inpEmail"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Email"
                    disabled="disabled"
                    :value="$store.getters.user.email"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="inpOffice">Office</label>
                  <input
                    id="inpOffice"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Office"
                    :value="office"
                    @input="
                      $store.commit('updateDetails', {
                        office: $event.target.value
                      })
                    "
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button
                  class="btn btn-primary"
                  :disabled="!$store.state.user.userChanges"
                >
                  <i class="fa fa-save"></i>&nbsp; Update Details
                </button>
              </div>
            </div>
          </slot>
        </card>
      </div>

      <div class="col-12 col-xl-3"></div>
    </div>
  </div>
</template>

<script>
import Card from "./Cards/Card";
export default {
  name: "UserDetails",
  components: {
    Card
  },
  computed: {
    forename() {
      const fore = this.$store.getters.user.forename;
      return fore ? fore : "";
    },
    surname() {
      const surn = this.$store.getters.user.surname;
      return surn ? surn : "";
    },
    office() {
      const office = this.$store.getters.user.office;
      return office ? office : "";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
